.formInputWrapper {
    text-align: left;
    margin-bottom: 0rem;
    /* background: white; */
    padding: 1rem;
    border-radius: 3px;
}

.selectWrapper {
    width: 60%;
}
.selectWrapperFull {
    width: 100%;
}

.reportsFormLabel {
    color: rgba(98, 108, 112, 1);
    font-weight: 500;
    font-size: 0.9rem;
}
.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container input {
    width: 100% !important;
    padding-block: 0.54rem !important;
    padding-inline: 0.54rem !important;
    background: rgba(242, 243, 244, 0.5) !important;
    border:  1px solid #dee2e6 !important ;
    border-radius: 0.375rem !important;
    color: rgba(98, 108, 112, 1) !important;
    font-size: 0.8rem !important;
    outline: none !important;
}

.btn-wrapper {
    padding-block: 0.75rem;
    margin-top: 1.5rem !important;
    background: rgba(42, 124, 118, 1);
    border-radius: 4px;
    display: flex;
    /* padding-inline: 1.5rem; */
    justify-content: center;
    align-items: center;
    width: 15% !important;
    cursor: pointer;
}

.btn-wrapperDisabled {
    padding-block: 0.50rem;
    margin-top: 1.5rem !important;
    background: rgb(188, 196, 196);
    color: black;
    border-radius: 4px;
    display: flex;
    /* padding-inline: 1.5rem; */
    justify-content: center;
    align-items: center;
    width: 15% !important;
    cursor: default;
    pointer-events: none;
}

.btn-wrapperDisabled a {
    pointer-events: none;
    cursor: default;
}

.btn-wrapperDisabled .reportGenBtn {
    text-decoration: none;
    font-size: 0.9rem;
    color: black;
    font-weight: 500;
} 

.btnFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.reportGenBtn {
    text-decoration: none;
    font-size: 0.9rem;
    color: white;
    font-weight: 500;
    /* background-color: red; */
}

/* .reportsWrapper {
    width: 90%;
    padding: 20px;
}
@media(max-width : 480px){
    .reportsWrapper{
        width: 100%;
        padding: 20px;
    }
} */

.reportModalVehicleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.downloadIconReportModal {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.reportVehicleNo {
    color: black;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
}

.modal-title {
    font-size: 1rem !important;
}

.vehicleModel {
    color: rgba(88, 88, 88, 1);
    font-weight: 400;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.reportModalContainer {
    padding-block: 0.5rem;
    background: rgba(229, 241, 242, 1);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0.5rem;
    margin-top: 1rem;
}

.hrWrapper {
    border-left: 1.5px solid rgba(0, 0, 0, 1);
    height: 80px;
}

.uniqueIdText {
    margin-bottom: 0.35rem;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
}

.uniqueId {
    margin-bottom: 0.35rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
}

.reportDetailKey {
    margin-bottom: 0.35rem;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
}

.reportDetailValue {
    margin-bottom: 0.35rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
}
.report-details {
    padding: 0px 20px 0px 12px;
}
.report-details .selectWrapperFull {
    width: 18rem;
}
@media (min-width : 300px) and (max-width : 380px) {
    .report-details .selectWrapperFull {
        width: 100%;
    }
}
@media (min-width : 381px) and (max-width : 480px) {
    .report-details .selectWrapperFull {
        width: 100%;
    }
}
@media (min-width : 480px) and (max-width : 570px){
    .report-details .selectWrapperFull {
        width: 100%;
    }
}
@media (min-width : 580px) and (max-width : 759px) {
    .report-details .selectWrapperFull {
        width: 14rem;
    }
}
.report-btn-parent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.report-search-btn {
    border: 0 !important;
    background: #2A7C76 !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    padding: 8px 15px !important;
}