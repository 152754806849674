.rounded-circle-profile-pic {
   border: 4px solid #fff !important;
   position: absolute;
   bottom: -6px;
   left: 15px;
}
.ml-150 {
    margin-left: 150px;
}
.profile-title {
    font-size: 20px;
    font-weight: 600;
}
.info-title {
    color: #959FA3;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.info-value {
    color: #191B1C;
    font-size: 15px;
    font-weight: 400;
}
.cursor-pointer {
    cursor: pointer;
}