.vehicleElement {
    background-color: white;
    border-radius: 5px;
}

.vehicle {
    padding: 20px;
}

.vehicle .nav {
    /* border: none !important; */
    border-width: 1.5px  !important;
    border-color: #e9dede !important;
}

.vehicle .nav .nav-item .active {
    border: none !important;
    background: #4C7889 !important;
    outline: none !important;
    color: white !important;
}

.vehicle .nav .nav-item:hover {
    border: none  !important;
    outline: none !important;
}

.vehicle .nav .nav-item .nav-link {
    border: none !important;
    background: #dbe6ebdc;
    outline: none !important;
    color: black;
    font-size: 0.85rem !important;
    font-weight: 600 !important;
    /* padding-inline: 1.5rem; */
    margin-inline: 0.5rem;
    margin-bottom: 0.03rem;
}