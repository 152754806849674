/* ProgressBar.css */
.progress-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

.progress-bar-container {
    width: 100%;
    max-width: 230px;
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-bar-fill {
    height: 7px;
    /* background-color: #4caf50;  */
    border-radius: 5px 0 0 5px;
    transition: width 0.3s ease-in-out;
  }
  
  .progress-bar-text {
    margin-top: 0px; 
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
  