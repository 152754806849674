.rounded-circle-profile {
    border-radius:50%;
    width:130px;
    height:130px;
    overflow: hidden;
}
.rounded-circle-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire circle */
}
.upload-btn {
    width: 250px;
    border: 2px solid #2A7C76 !important;
    border-radius: 160px !important;
    color: #2A7C76 !important;
    font-size: 14px !important;
}
.upload-btn:hover {
    background-color: #2A7C76 !important;
    border-color: #2A7C76 !important;
    color: #fff !important;
}
.note {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}