.topbar-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    direction: ltr;
    height: 60px;
}

.icon-style {
    height: 30px;
    width: 30px;
    margin-inline: 6px;
}

.profile-link {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 48px;
    max-height: 48px;
    margin-inline: 10px;
}
.profile-img-link {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 60px;
    max-height: 60px;
}
.selectInternalWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 5px;
}

.selectInputText {
    margin-block: 0px;
    padding-inline: 5px;
}

.select-wrapper {
    margin-inline: 8px;
}

.profileHeader {
    display: flex;
    align-items: center;
    padding-inline: 14px;
    padding-block: 10px;
}

.profileDetails {
    margin-left: 12px;
}

.userName {
    font-size: 1rem;
    font-weight: 600;
    color: black;
    margin-block: 0.35rem;
    cursor: pointer;
}

.userEmail {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(98, 108, 112, 1);
    margin-block: 0.35rem;
    cursor: pointer;
}

.profilePhoto {
    width: 2.75rem;
    height: 2.75rem
}

.menuWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 12px;
    cursor: pointer;
}

.profileIconStyle {
    width: 1.25rem;
    height: 1.25rem;
}

.profileKey {
    margin-block: 0.5rem;
    font-size: 0.9rem;
    color: black;
    padding-inline: 1rem;
    font-weight: 500;
}

#popover-contained {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
    border: none !important;
    margin-top: 7px !important;
    width: 17vw;
}

#popover-contained-notification {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
    border: none !important;
    margin-top: 7px !important;
    width: 30vw;
}

hr {
    border-top : 1px solid hsl(0, 0%, 80%) !important;
}

.notificationTitle {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
}

.notificationDateTime {
    font-weight: 500;
    font-size: 0.8rem;
    color: #585858;
    margin-bottom: 0.3rem;
}

.notificationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 22px;
        padding-bottom: 3px;
        padding-top: 3px;
}

.notificationMsg {
    font-size: 0.8rem;
    color: #585858;
    font-weight: 400;
    padding-inline-start:22px;
    width: 95%;
    margin-block: 0.1rem;
}

.notificationPopover-wrapper {
    max-height: 80vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.popover {
    max-width: 100% !important;
}

::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c4c4; 
    border-radius: 10px;
  }

  .headingCss {
    margin-block: 0.7rem;
    padding-inline: 18px;
    font-size: 1.1rem;
    color: #585858;
    font-weight: 600;
  }

  .heading hr {
    border-top : 2px solid hsl(0, 0%, 65%) !important; 
  }
  .documents {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  .css-1dimb5e-singleValue {
    color: #626C70 !important; /* Change placeholder color as needed */
  }
  .profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
@media (min-width : 579px) and (max-width : 780px) {
    #popover-contained {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
        border: none !important;
        margin-top: 7px !important;
        width: 40vw;
    }
}
@media (min-width : 781px) and (max-width : 1080px) {
    #popover-contained {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
        border: none !important;
        margin-top: 7px !important;
        width: 35vw;
    }
}
@media (min-width : 1081px) and (max-width : 1380px) {
    #popover-contained {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
        border: none !important;
        margin-top: 7px !important;
        width: 25vw;
    }
}
@media (min-width : 1381px) and (max-width : 1550px) {
    #popover-contained {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px !important;
        border: none !important;
        margin-top: 7px !important;
        width: 20vw;
    }
}