.documentTabWrapper .nav {
    margin-bottom: 1rem !important;
    padding-block: 0.05rem !important;
    border: none;
}

.documentTabWrapper .nav .nav-item .nav-link {
    border: none;
    padding-block: 1rem;
    padding-inline: 1.5rem;
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 500;
    font-size: 0.9rem;
}

.documentTabWrapper .nav .nav-item .active {
    border-bottom: 3px solid rgba(76, 186, 186, 1) !important;
}

.linkViewImage {
    cursor: pointer;
    text-decoration: underline;
    color: #5656b8;
}

.documentTypeLabel {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.inputVehicleNo {
    padding-block: 0.44rem;
    padding-inline: 0.44rem;
    border-radius: 0.350rem;
    border: 1px solid hsl(0%,0%,20%);
    border: 1.5px solid hsl(0, 0%, 80%);
    color: hsl(0, 0%, 20%);
    font-size: 14px;
    width: 100%;
    outline: none;
}

input[type="file" i] {
    border: 1px solid hsl(0, 0%, 80%);
    padding-inline: 0.5rem;
    padding-block: 0.5rem;
    width: 100%;
    font: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.320rem;
    color: hsl(0, 0%, 20%);
}

::file-selector-button {
    display: none;
  }

.expirationDateWrapper .react-datepicker__input-container input {
    width: 100% !important;
    padding-block: 0.44rem !important;
    padding-inline: 0.44rem !important;
    background: none !important;
    /* border: none !important; */
    border: 1px solid hsl(0, 0%, 80%) !important; 
    border-radius: 0.350rem !important;
    color: hsl(0, 0%, 20%) !important;
    font-size: 0.9rem !important;
    outline: none !important;
}

.documentModalBtnWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.documentModalSaveBtn {
    padding-inline: 1rem;
    margin-inline: 0.5rem;
    padding-block: 0.25rem;
    background: rgba(42, 124, 118, 1);
    color: white;
    border-radius: 1rem;
    cursor: pointer;
}

.documentModalCancelBtn {
    padding-inline: 1rem;
    margin-inline: 2rem;
    padding-block: 0.25rem;
    background: rgba(245, 246, 247, 1);
    color: black;
    border-radius: 1rem;
    cursor: pointer;
}

.documentModalDisableBtn {
    padding-inline: 1rem;
    margin-inline: 2rem;
    padding-block: 0.25rem;
    background: rgba(245, 246, 247, 1);
    color: black;
    border-radius: 1rem;
    cursor: default;
}

#documentPageId .ichLPD {
    display: none !important;
}
