.formInputWrapper {
    text-align: left;
    margin-bottom: 0rem;
    /* background: white; */
    padding: 0.5rem;
    border-radius: 3px;
}

.selectWrapper {
    width: 60%;
}
.selectWrapperFull {
    width: 90%;
}

.reportsFormLabel {
    color: rgba(98, 108, 112, 1);
    font-weight: 500;
    font-size: 0.9rem;
}
.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container input {
    width: 100% !important;
    padding-block: 0.54rem !important;
    padding-inline: 0.54rem !important;
    background: rgba(242, 243, 244, 0.5) !important;
    /* border: none !important; */
    border-radius: 3px !important;
    color: rgba(98, 108, 112, 1) !important;
    font-size: 0.8rem !important;
    outline: none !important;
}

.btn-wrapper {
    padding-block: 0.5rem;
    margin-top: 1.5rem !important;
    background: rgba(42, 124, 118, 1);
    border-radius: 4px;
    display: flex;
    /* padding-inline: 1.5rem; */
    justify-content: center;
    align-items: flex-end;
    width: 15%;
    cursor: pointer;
    float: right;
}
.btn-wrapper-trip {
    padding-block: 0.5rem;
    margin-top: 1.5rem !important;
    background: rgba(42, 124, 118, 1);
    border-radius: 4px;
    display: flex;
    /* padding-inline: 1.5rem; */
    justify-content: center;
    align-items: flex-end;
    width: 100px;
    cursor: pointer;
    float: right;
}
.detailsWrapper {
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding-inline: 20px;
    padding-block: 20px;
    text-align: left;
}

.fastTagWrapper {
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding-inline: 20px;
    padding-block: 20px;
    text-align: left;
}

.detailsHead {
    margin-bottom: 0.5rem;
    color: rgba(101, 101, 117, 1);
    font-weight: 500;
}

.tripVehicleNo {
    margin-bottom: 0.5rem;
    color: rgba(79, 79, 81, 1);
    font-weight: 600;
    font-size: 0.9rem;
}

.tripOtherDetailsWrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
}

.tripOtherDetail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tripSvg {
    width: 100%;
}

.fromText {
    margin-bottom: 0.3rem;
    color: rgba(0, 0, 0, 1);
    font-size: 0.8rem;
    font-weight: 700;
}

.fromLocation {
    margin-bottom: 0.3rem;
    color: rgba(101, 101, 117, 1);
    font-size: 0.8rem;
    /* font-weight: 700; */
    /* width: 150px; */
    flex-wrap: wrap;
}
.toText {
    text-align: right;
    margin-bottom: 0.3rem;
    color: rgba(0, 0, 0, 1);
    font-size: 0.8rem;
    font-weight: 700;
    /* width: 150px; */
    flex-wrap: wrap;
}

.toLocation {
    text-align: right;
    margin-bottom: 0.3rem;
    color: rgba(101, 101, 117, 1);
    font-size: 0.8rem;
    /* font-weight: 700; */
}

.btnFlex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 96.5% !important;
}

.tripForm1 {
    background: white;
    border-radius: 6px;
    padding-bottom: 12px;
    margin: 10px;
    box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.19);
}
.tripForm2 {
    /* background: white; */
    
    border-radius: 6px;
    padding-top: 12px;
    margin: 10px;
    /* box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.19); */
}

.routeWrapper{
     /* background: white; 
    box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.19);
    border-radius: 6px; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.25rem;
}
/* .tripForm2 {
    background: rgb(247, 244, 244);
    border-radius: 6px;
    padding-bottom: 12px;
    margin-inline: 0px;
    margin-top: 2rem !important;
} */

.reportGenBtn {
    text-decoration: none;
    font-size: 0.9rem;
    color: white;
    font-weight: 500;
}

.tripCalculatorWrapper {
    width: 93%;
    padding: 20px;
}

.tripMileage {
    width: 35%;
}

.tripDetailsWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.19);
}

.tripBoxWrapper {
    background: rgba(244, 244, 244, 1);
    padding-inline: 14px;
    padding-block: 4px;
    border-radius: 6px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* justify-items: center; */
    text-align: left;
    width: 22.5%
}

.tripKey {
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
    align-items: flex-end;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 700;
}

.tripValue {
    margin-bottom: 0.5rem;
    text-align: left;
    color: rgba(101, 101, 117, 1);
    font-size: 0.7rem;
    font-weight: 500;
}

.currentBalanceWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(209, 209, 209, 1);
    border-radius: 3px;
    padding-inline: 12px;
    padding-block: 8px;
    margin-top: 1.5rem;
}

.currentBalKey {
    margin-bottom: 0px;
    color: rgba(101, 101, 117, 1);
    font-size: 0.8rem;
}

.currentBalValue {
    margin-bottom: 0px;
    color: rgba(101, 101, 117, 1);
    font-size: 1rem;
}

.costOfTripWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.costOfTrip {
    border: 1px solid rgba(209, 209, 209, 1);
    border-radius: 3px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 45%;
}

.lowBallance {
    border: 1px solid rgba(209, 209, 209, 1);
    border-radius: 3px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 45%;
}

.tripUpArrowIcon {
    width: 1rem;
    display: inline;
}

.tripDownArrowIcon {
    width: 1rem;
    display: inline;
}

.costTripText {
    color: rgba(87, 87, 87, 1);
    font-size: 0.7rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.lowBallanceText {
    color: rgba(87, 87, 87, 1);
    font-size: 0.7rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.costOfTripInRs {
    font-size: 1.2rem;
    margin-bottom: 0rem;
    color: rgba(87, 87, 87, 1);
    font-weight: 500;
}

.lowBallanceInRs {
    font-size: 1.2rem;
    margin-bottom: 0rem;
    color: rgba(250, 5, 5, 1);
    font-weight: 500;
}

.rechargeBtnWrapper {
    width: 100%;
    background: rgba(253, 165, 55, 1);
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}

.rechargeBtn {
    padding-inline: 14px;
    padding-block: 7px;
}

.fastagBtnWrapper {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
}

.mapWrapper {
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;
}

.userInput {
    font-size: 0.8rem;
    color: hsl(0, 0%, 20%);
    border-radius: 0.375rem;
    padding-inline: 0.54rem;
    padding-block: 0.54rem;
    width: 100%;
    border: 1.5px solid rgba(229, 231, 232, 1);
    background: rgba(242, 243, 244, 0.5);
    outline: none
}
@media (max-width : 480px) {
    .selectWrapperFull{
        width : 100%
    }
}