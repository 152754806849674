.vehicleComponentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vehicleNo {
  font-size: 0.8rem;
  font-weight: 700;
  margin-block: 0.35rem;
}

.vehicleStatusText {
  font-size: 0.6rem;
  margin-block: 0.2rem;
}
.vehicleComponent {
  padding-inline: 22px;
  padding-block: 6px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.1);
}
.plan-expired {
  opacity: 0.5;
}
.plan-expired-alert {
  padding: 3px;
  color: #58151c;
  position: absolute;
  width: 200%;
  bottom: -16px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: inset 0px 2px 1px 1px #00000030;
}
.vehicleAddress {
  margin-block: 0.25rem;
  font-size: 0.65rem;
  text-align: left;
  color: #585858;
  height: 50px;
}

.vehicleTravelledToday {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.65rem;
  text-align: left;
  color: #585858;
}
.plan-expire-soon {
  color: red;
  font-weight: 600;
}
.plan-upgrade {
  color: green;
  font-weight: 600;
  text-decoration: none;
}
.vehicleLastRecordTime {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.65rem;
  font-weight: 300;
  text-align: left;
  color: #585858;
}

.vehicleDetailsHeading {
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  margin-block: 0.35rem;
}

.vehicleDetialBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  padding-inline: 4px;
  padding-block: 1px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-block: 4px;
}

.vehicleBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 0.35rem;
  /* margin-inline: 1rem; */
}

.vehicleIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.vehicleDetailKey {
  font-size: 0.6rem;
  margin-block: 0.15rem;
  color: black;
  text-align: left;
  font-weight: 500;
}
.vehicleDetailValue {
  font-size: 0.6rem;
  margin-block: 0.15rem;
  color: black;
  text-align: left;
  font-weight: 500;
}

hr {
  margin-block: 0.35rem;
  margin-inline: 0.1rem;
}

.vehicleStatus {
  background-color: rgba(34, 176, 125, 1);
  padding-inline: 16px;
  padding-block: 2px;
  border-radius: 6px;
  color: white;
}

.vehicleStatusIdle {
  background-color: rgba(247, 210, 79, 1);
  padding-inline: 16px;
  padding-block: 2px;
  border-radius: 6px;
  color: white;
}

.vehicleStatusStopped {
  background-color: rgba(226, 71, 63, 1);
  padding-inline: 16px;
  padding-block: 2px;
  border-radius: 6px;
  color: white;
}

.vehicleStatusNa {
  background-color: rgba(128, 128, 128, 1);
  padding-inline: 16px;
  padding-block: 2px;
  border-radius: 6px;
  color: white;
}
.vehicleComponentHeaderLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.font-13 {
  font-size: 13px;
}
