.containerGeofence {
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 20px;
}
.switch input {
  height: 0;
  width: 0;
  opacity: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #0faf62;
  transition: 0.4s;
  border-radius: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  background-color: white;
  left: 4px;
  bottom: 4px;
  transition: 0.4s;
  border-radius: 12px;
}

input:checked + .slider {
  background-color: #0faf62;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
